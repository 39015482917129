<template>
  <ion-page>
    <ion-content :fullscreen="true" class="bg-lighter ion-content">
      <div class="nk-wrap nk-wrap-nosidebar">
        <div class="nk-content">
          <div class="nk-block nk-block-middle wide-xs mx-auto">
            <div class="nk-block-content nk-error-ld text-center">
              <h1 class="nk-error-head">404</h1>
              <h3 class="nk-error-title">{{ $t('404.heading')}}</h3>
              <p class="nk-error-text">{{ $t('404.message') }}</p>
              <nk-button v-on:click="$router.go(-1)" class="btn btn-lg btn-primary mt-2">{{ $t('404.back_button')}}</nk-button>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from '@ionic/vue';
import {defineComponent} from 'vue';

export default defineComponent({
  components: {
    //NavBar,
    //VerticalNavMenu,
    IonContent,
    IonPage,
  },
});
</script>
